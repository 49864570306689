html {
  font-size: 16px;
}
/*@media (max-width: 900px) {
  html { font-size: 14px; }
}
@media (max-width: 400px) {
  html { font-size: 14px; }
}*/

body,
#root {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100%;
    display: flex;
    font-family: "WorkSans-Regular", sans-serif;

    --fw-normal: 400;
    --fw-regular: var(--fw-normal);
    --fw-medium: 500;
    --fw-bold: 700;
    --fw-black: 900;
    --max-width: 1920px;
    --input-shadow: 4px;
    --avatar-shadow: 6px;
    --topbar-padding: 30px;
    --topbar-min-height: 32px;
    --space-s: 20px;
    --space-m: 40px;
    --space-l: 50px;
    --space-xl: var(--space-l);

    --border-color: #000;
    --form-error-color: #e64c25;
    --form-valid-color: #03a076;
    --shadow-thickness: 11px;
    --cream-color-000: #f5f3ef;
    --border-cream-color-000: #ebe7df;
    --cream-color-400: #f2eee8;
    --border-cream-color-400: #e9e2d8;
    --cream-color-900: #e7e1d8;
    --border-cream-color-900: #ddd5c8;
    --cream-color-800: #ece7e0;
    --border-cream-color-800: #e2dbd0;
    --cream-color-700: #efeae4;
    --border-cream-color-700: #e6ded4;
    --dark-color-900: #000;

    --color-200: #c0f6e0;
}

::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #727789;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #90C4C2;
    border-radius: 10px;
}


body {
    position: relative;
    background-size: cover;
    overflow-x: hidden;
}

main {
    background: #FFFFFF !important;
}

header {
    height: 5rem !important;
    padding:  0 !important;
}

/*@font-face {
    font-family: "Museo-Regular";
    src: local("Museo-Regular"), url(./assets/fonts/Museo300-Regular.otf) format("opentype");
}*/

@font-face {
    font-family: "WorkSans-Light";
    src: local("WorkSans-Light"), url(./assets/fonts/WorkSans-Light.ttf) format("truetype");
}

@font-face {
    font-family: "WorkSans-Regular";
    src: local("WorkSans-Regular"), url(./assets/fonts/WorkSans-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "WorkSans-SemiBold";
    src: local("WorkSans-SemiBold"), url(./assets/fonts/WorkSans-SemiBold.ttf) format("truetype");
}

@font-face {
    font-family: "WorkSans-Bold";
    src: local("WorkSans-Bold"), url(./assets/fonts/WorkSans-Bold.ttf) format("truetype");
}

@font-face {
    font-family: "WorkSans-ExtraBold";
    src: local("WorkSans-ExtraBold"), url(./assets/fonts/WorkSans-ExtraBold.ttf) format("truetype");
}


.p-0 {
    padding: 0 0 0 0 !important;
}

.p-2 {
    padding: 16px;
}

.px-2 {
    padding-left: 16px;
    padding-right: 16px;
}

.px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.py-2 {
    padding-top: 16px;
    padding-bottom: 16px;
}

.d-flex {
    display: flex !important;
    align-items: center;
    line-height: 1;
}

.d-flex-baseline {
    display: flex !important;
    align-items: baseline;
    line-height: 1;
}

.d-space {
    justify-content: space-between;
}

.d-center {
    justify-content: center;
}

.d-block {
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
}

.d-around {
    justify-content: space-around;
}

.f-right {
    float: right !important;
}

.m-auto {
    margin: auto;
}

.t-center {
    text-align: center !important;
}

.t-right {
    text-align: right;
}

.t-left {
    text-align: left;
}

.w-full {
    width: 100%;
}

.mt-5 {
    margin-top: 2.75rem !important;
}

.mt-4 {
    margin-top: 2rem !important;
}

.mt-3 {
    margin-top: 1.5rem !important;
}

.mt-2 {
    margin-top: 1rem !important;
}

.mt-1 {
    margin-top: 0.5rem !important;
}

.mt-0 {
    margin-top: 0rem !important;
}

.mt-auto {
    margin-top: auto !important;
}

.mb-5 {
    margin-bottom: 2.75rem !important;
}

.mb-4 {
    margin-bottom: 2rem !important;
}

.mb-3 {
    margin-bottom: 1.5rem !important;
}

.mb-2 {
    margin-bottom: 1rem !important;
}

.mb-1 {
    margin-bottom: 0.5rem !important;
}

.mb-0 {
    margin-bottom: 0rem !important;
}

.mb-auto {
    margin-bottom: auto !important;
}

.mr-5 {
    margin-right: 2.75rem !important;
}

.mr-4 {
    margin-right: 2rem !important;
}

.mr-3 {
    margin-right: 1.5rem !important;
}

.mr-2 {
    margin-right: 1rem !important;
}

.mr-1 {
    margin-right: 0.5rem !important;
}

.mr-0 {
    margin-right: 0rem !important;
}

.mr-auto {
    margin-right: auto !important;
}

.ml-5 {
    margin-left: 2.75rem !important;
}

.ml-4 {
    margin-left: 2rem !important;
}

.ml-3 {
    margin-left: 1.5rem !important;
}

.ml-2 {
    margin-left: 1rem !important;
}

.ml-1 {
    margin-left: 0.5rem !important;
}

.ml-0 {
    margin-left: 0rem !important;
}

.ml-auto {
    margin-left: auto !important;
}

.pt-5 {
    padding-top: 2.75rem !important;
}

.pt-4 {
    padding-top: 2rem !important;
}

.pt-3 {
    padding-top: 1.5rem !important;
}

.pt-2 {
    padding-top: 1rem !important;
}

.pt-1 {
    padding-top: 0.5rem !important;
}

.pb-5 {
    padding-bottom: 2.75rem !important;
}

.pb-4 {
    padding-bottom: 2rem !important;
}

.pb-3 {
    padding-bottom: 1.5rem !important;
}

.pb-2 {
    padding-bottom: 1rem !important;
}

.pb-1 {
    padding-bottom: 0.5rem !important;
}

.pr-5 {
    padding-right: 2.75rem !important;
}

.pr-4 {
    padding-right: 2rem !important;
}

.pr-3 {
    padding-right: 1.5rem !important;
}

.pr-2 {
    padding-right: 1rem !important;
}

.pr-1 {
    padding-right: 0.5rem !important;
}

.pl-5 {
    padding-left: 2.75rem !important;
}

.pl-4 {
    padding-left: 2rem !important;
}

.pl-3 {
    padding-left: 1.5rem !important;
}

.pl-2 {
    padding-left: 1rem !important;
}

.pl-1 {
    padding-left: 0.5rem !important;
}

.px-5 {
    padding-left: 2.75rem !important;
    padding-right: 2.75rem !important;
}

.px-4 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
}

.px-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
}

.px-2 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.px-1 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
}

.mx-5 {
    margin-left: 2.75rem !important;
    margin-right: 2.75rem !important;
}

.mx-4 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
}

.mx-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
}

.mx-2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
}

.mx-1 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
}

.mh-5 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
}

.mh-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
}

.mh-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
}

.mh-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.mh-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

.m-visible {
    display: none;
}

.d-visible {
    display: block;
}

.p-right {
    position: absolute;
    right: 1rem;
}

.t-gray {
    color: #95999A !important;
}

.ant-layout-footer {
    padding: 0 !important;
}

@media only screen and (min-width: 300px) and (max-width: 900px){
    .m-visible {
        display: flex;
    }

    .d-visible {
        display: none !important;
    }
}

.receipt {
  position: relative;
  background: #277688;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 5rem;

  .cloud {
    position: absolute;
    right: 0;
    top: 40%;
  }

  .container {
    position: relative
  }

  .flamingo {
    position: absolute;
    right: 10%;
    bottom: 200%;
  }

  .title {
    line-height: 28px;
    font-size: 24px;
    font-family: WorkSans-Bold;
    text-align: center;
    color: #FFFFFF;
  }

  @media (max-width: 800px) {
    padding: 2rem;

    .container {
      position: initial;
    }

    .flamingo {
      top: 2rem;
      left: 5rem;
    }

    .cloud {
      top: 5rem;
    }
  }
}
